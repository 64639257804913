.cs-partner_logo_wrap {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
}


.cs-partner_logo {
  padding: 15px;
}

@media screen and (max-width: 575px) {
  .cs-partner_logo_wrap {
    margin: 0 -10px;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  height: 130px;
  width: 10%;
  margin: 15px;

  background-color: #ffffff;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 1024px) {
  .flex-item {
    height: 180px;
    width: 30%;
    margin: 15px;
    background-size: contain;
  }
}

@media screen and (max-width: 768px) {
  .flex-item {
    height: 180px;
    width: 40%;
    margin: 15px;
    background-size: contain;
  }
}

@media screen and (max-width: 412px) {
  .flex-item {
    height: 180px;
    width: 100%;
    margin: 15px;
    background-size: contain;
  }
}